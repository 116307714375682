define("ember-intl/helpers/t", ["exports", "ember-intl/helpers/-format-base"], function (_exports, _formatBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formatBase.default.extend({
    format: function format(key, options) {
      (true && !(key) && Ember.assert('[ember-intl] translation lookup attempted but no translation key was provided.', key));
      return this.intl.t(key, options);
    }
  });

  _exports.default = _default;
});