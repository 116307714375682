define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QeG03WAI",
    "block": "{\"symbols\":[\"@value\",\"@type\",\"&default\"],\"statements\":[[15,3,[[29,\"hash\",null,[[\"button\"],[[29,\"component\",[[24,0,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[24,2,[]],[24,1,[]],[24,0,[\"buttonPressed\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });

  _exports.default = _default;
});