define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eNWbc+DN",
    "block": "{\"symbols\":[\"calendar\",\"&default\"],\"statements\":[[4,\"with\",[[29,\"assign\",[[25,[\"publicAPI\"]],[29,\"hash\",null,[[\"nav\",\"days\"],[[29,\"component\",[[25,[\"navComponent\"]]],[[\"calendar\"],[[29,\"readonly\",[[25,[\"publicAPI\"]]],null]]]],[29,\"component\",[[25,[\"daysComponent\"]]],[[\"calendar\"],[[29,\"readonly\",[[25,[\"publicAPI\"]]],null]]]]]]]],null]],null,{\"statements\":[[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"    \"],[15,2,[[24,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,1,[\"nav\"]],false],[0,\"\\n    \"],[1,[24,1,[\"days\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs"
    }
  });

  _exports.default = _default;
});