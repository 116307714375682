define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RmohXJJ2",
    "block": "{\"symbols\":[\"Element\",\"@title\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[1,[29,\"unbound\",[[25,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,0,[\"inDom\"]]],null,{\"statements\":[[4,\"let\",[[29,\"component\",[[24,0,[\"elementComponent\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[24,1,[]],[[14,4]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@title\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[24,0,[\"placement\"]],[24,0,[\"fade\"]],[24,0,[\"showHelp\"]],[24,2,[]],[24,0,[\"_renderInPlace\"]],[24,0,[\"triggerTargetElement\"]],[24,0,[\"destinationElement\"]],[24,0,[\"autoPlacement\"]],[24,0,[\"viewportElement\"]],[24,0,[\"viewportPadding\"]],[24,0,[\"overlayId\"]],[24,3,[]]]],{\"statements\":[[0,\"\\n      \"],[15,5,[[29,\"hash\",null,[[\"close\"],[[24,0,[\"close\"]]]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
    }
  });

  _exports.default = _default;
});