define("ember-bootstrap/templates/components/bs-form/element/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KId6Onzz",
    "block": "{\"symbols\":[\"@labelClass\",\"@formElementId\",\"@label\",\"&default\"],\"statements\":[[4,\"if\",[[26,4]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[9],[0,\"\\n    \"],[15,4],[0,\"\\n    \"],[1,[24,3,[]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[30,[\"control-label \",[29,\"if\",[[24,0,[\"invisibleLabel\"]],\"sr-only\"],null],\" \",[24,1,[]]]]],[12,\"for\",[24,2,[]]],[9],[1,[24,3,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/label.hbs"
    }
  });

  _exports.default = _default;
});