define("ember-popper/templates/components/ember-popper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nlfoFn9E",
    "block": "{\"symbols\":[\"@id\",\"@class\",\"@ariaRole\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"renderInPlace\"]]],null,{\"statements\":[[0,\"  \"],[8,\"div\"],[13,\"id\",[24,1,[]]],[13,\"class\",[24,2,[]]],[13,\"hidden\",[24,0,[\"hidden\"]]],[13,\"role\",[24,3,[]]],[14,4],[9],[0,\"\\n    \"],[15,5,[[29,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[29,\"action\",[[24,0,[]],\"disableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"enableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"scheduleUpdate\"],null],[29,\"action\",[[24,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[3,\"ref\",[[24,0,[]],\"_popperElement\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[24,0,[\"_popperContainer\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[8,\"div\"],[13,\"id\",[24,1,[]]],[13,\"class\",[24,2,[]]],[13,\"hidden\",[24,0,[\"hidden\"]]],[13,\"role\",[24,3,[]]],[14,4],[9],[0,\"\\n    \"],[15,5,[[29,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[29,\"action\",[[24,0,[]],\"disableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"enableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"scheduleUpdate\"],null],[29,\"action\",[[24,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[3,\"ref\",[[24,0,[]],\"_popperElement\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-popper/templates/components/ember-popper.hbs"
    }
  });

  _exports.default = _default;
});