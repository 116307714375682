define("ember-bootstrap/templates/components/bs-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mcd6thtb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[27,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"title\",\"body\"],[[29,\"component\",[[24,0,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[24,0,[\"collapsed\"]],[24,0,[\"disabled\"]],[29,\"action\",[[24,0,[]],[25,[\"onClick\"]],[25,[\"value\"]]],null]]]],[29,\"component\",[[24,0,[\"bodyComponent\"]]],[[\"collapsed\"],[[24,0,[\"collapsed\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"component\",[[24,0,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[24,0,[\"collapsed\"]],[24,0,[\"disabled\"]],[29,\"action\",[[24,0,[]],[25,[\"onClick\"]],[25,[\"value\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[23,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[24,0,[\"bodyComponent\"]]],[[\"collapsed\"],[[24,0,[\"collapsed\"]]]],{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs"
    }
  });

  _exports.default = _default;
});