define("ember-power-calendar/components/power-calendar-multiple", ["exports", "ember-power-calendar/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-multiple/days',
    _calendarType: 'multiple',
    // CPs
    selected: Ember.computed({
      get: function get() {
        return undefined;
      },
      set: function set(_, v) {
        return Ember.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }
    }),
    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');

      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Actions
    actions: {
      select: function select(dayOrDays, calendar, e) {
        (true && !(Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date) && Ember.assert("The select action expects an array of date objects, or a date object. ".concat(_typeof(dayOrDays), " was recieved instead."), Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date));
        var action = this.get("onSelect");
        var days;

        if (Ember.isArray(dayOrDays)) {
          days = dayOrDays;
        } else if (dayOrDays instanceof Object && dayOrDays.date instanceof Date) {
          days = [dayOrDays];
        }

        if (action) {
          action(this._buildCollection(days), calendar, e);
        }
      }
    },
    // Methods
    _buildCollection: function _buildCollection(days) {
      var selected = this.get("publicAPI.selected") || [];

      var _iterator = _createForOfIteratorHelper(days),
          _step;

      try {
        var _loop = function _loop() {
          var day = _step.value;
          var index = selected.findIndex(function (selectedDate) {
            return (0, _emberPowerCalendarUtils.isSame)(day.date, selectedDate, "day");
          });

          if (index === -1) {
            selected = [].concat(_toConsumableArray(selected), [day.date]);
          } else {
            selected = selected.slice(0, index).concat(selected.slice(index + 1));
          }
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: selected
      });
    }
  });

  _exports.default = _default;
});