define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4D24oQDM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"icon\"]]],null,{\"statements\":[[7,\"i\"],[12,\"class\",[24,0,[\"icon\"]]],[9],[10],[0,\" \"]],\"parameters\":[]},null],[1,[24,0,[\"text\"]],false],[15,1,[[29,\"hash\",null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[24,0,[\"isFulfilled\"]],[24,0,[\"isPending\"]],[24,0,[\"isRejected\"]],[24,0,[\"isSettled\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});