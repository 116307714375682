define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "28NRoQDO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"item\",\"model\"],[[29,\"component\",[\"ember-sortable@sortable-item\"],[[\"direction\",\"registerItem\",\"deregisterItem\",\"setSelectedItem\",\"update\",\"prepare\",\"commit\",\"activateKeyDown\",\"deactivateKeyDown\"],[[25,[\"direction\"]],[29,\"action\",[[24,0,[]],\"registerItem\"],null],[29,\"action\",[[24,0,[]],\"deregisterItem\"],null],[29,\"action\",[[24,0,[]],\"setSelectedItem\"],null],[29,\"action\",[[24,0,[]],\"update\"],null],[29,\"action\",[[24,0,[]],\"prepare\"],null],[29,\"action\",[[24,0,[]],\"commit\"],null],[29,\"action\",[[24,0,[]],\"activateKeyDown\"],null],[29,\"action\",[[24,0,[]],\"deactivateKeyDown\"],null]]]],[25,[\"model\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-sortable/templates/components/sortable-group.hbs"
    }
  });

  _exports.default = _default;
});