define("ember-crumbly/templates/components/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45CAfPj/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"route\",\"linkable\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"route\",\"id\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[[25,[\"linkClass\"]],[25,[\"route\",\"path\"]],[25,[\"route\",\"id\"]]]],{\"statements\":[[0,\"        \"],[1,[25,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[[25,[\"linkClass\"]],[25,[\"route\",\"path\"]]]],{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"        \"],[15,1,[[24,0,[]],[25,[\"route\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1,[[24,0,[]],[25,[\"route\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-crumbly/templates/components/bread-crumb.hbs"
    }
  });

  _exports.default = _default;
});