define("ember-changeset/utils/validate-nested-obj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNestedObj;
  var keys = Object.keys;

  function validateNestedObj(label, obj) {
    keys(obj).forEach(function (key) {
      key.split('.').forEach(function (_, i, allParts) {
        if (i < allParts.length - 1) {
          var path = allParts.slice(0, i + 1).join('.');
          var msg = "Object \"".concat(label, "\" may not have keys that override each other.");
          (true && !(!(path in obj)) && Ember.assert(msg, !(path in obj)));
        }
      });
    });
  }
});