define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "26MP88AZ",
    "block": "{\"symbols\":[\"Element\",\"@title\",\"&default\",\"@class\",\"&attrs\"],\"statements\":[[1,[29,\"unbound\",[[25,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,0,[\"inDom\"]]],null,{\"statements\":[[4,\"let\",[[29,\"component\",[[24,0,[\"elementComponent\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[24,1,[]],[[14,5]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@renderInPlace\",\"@destinationElement\",\"@popperTarget\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[24,0,[\"placement\"]],[24,0,[\"fade\"]],[24,0,[\"showHelp\"]],[24,0,[\"_renderInPlace\"]],[24,0,[\"destinationElement\"]],[24,0,[\"triggerTargetElement\"]],[24,0,[\"autoPlacement\"]],[24,0,[\"viewportElement\"]],[24,0,[\"viewportPadding\"]],[24,0,[\"overlayId\"]],[24,4,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"        \"],[15,3,[[29,\"hash\",null,[[\"close\"],[[24,0,[\"close\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});