define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2y3tiSgF",
    "block": "{\"symbols\":[\"@inNav\",\"&default\"],\"statements\":[[15,2,[[29,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[29,\"component\",[[24,0,[\"buttonComponent\"]]],[[\"dropdown\",\"isOpen\",\"onClick\",\"onKeyDown\"],[[24,0,[]],[24,0,[\"isOpen\"]],[24,0,[\"toggleDropdown\"]],[24,0,[\"handleKeyEvent\"]]]]],[29,\"component\",[[24,0,[\"toggleComponent\"]]],[[\"dropdown\",\"isOpen\",\"inNav\",\"onClick\",\"onKeyDown\"],[[24,0,[]],[24,0,[\"isOpen\"]],[25,[\"inNav\"]],[24,0,[\"toggleDropdown\"]],[24,0,[\"handleKeyEvent\"]]]]],[29,\"component\",[[24,0,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[24,0,[\"isOpen\"]],[24,0,[\"direction\"]],[24,1,[]],[24,0,[\"toggleElement\"]],[24,0,[\"elementId\"]]]]],[24,0,[\"toggleDropdown\"]],[24,0,[\"openDropdown\"]],[24,0,[\"closeDropdown\"]],[24,0,[\"isOpen\"]]]]]]],[0,\"\\n\"],[4,\"if\",[[24,0,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"on-document\",[\"keydown\",[24,0,[\"handleKeyEvent\"]]],null],false],[0,\"\\n  \"],[1,[29,\"on-document\",[\"click\",[24,0,[\"closeHandler\"]]],[[\"capture\"],[true]]],false],[0,\"\\n  \"],[1,[29,\"on-document\",[\"keyup\",[24,0,[\"closeHandler\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});