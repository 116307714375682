define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiAHXT/v",
    "block": "{\"symbols\":[\"@toggleElement\",\"@dropdownElementId\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"_isOpen\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-popper\",[[13,\"class\",\"ember-bootstrap-dropdown-bs3-popper\"]],[[\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\",\"@onCreate\"],[[24,0,[\"popperPlacement\"]],[24,1,[]],[24,0,[\"_renderInPlace\"]],[24,0,[\"destinationElement\"]],[24,0,[\"popperModifiers\"]],[24,0,[\"setFocus\"]]]],{\"statements\":[[0,\"\\n    \"],[8,\"ul\"],[13,\"id\",[30,[[24,2,[]],\"__menu\"]]],[13,\"class\",[30,[\"dropdown-menu \",[24,0,[\"alignClass\"]],\" \",[29,\"if\",[[24,0,[\"isOpen\"]],\"show\"],null],\" \",[24,3,[]]]]],[13,\"role\",[24,0,[\"ariaRole\"]]],[13,\"tabindex\",\"-1\"],[14,4],[9],[0,\"\\n      \"],[15,5,[[29,\"hash\",null,[[\"item\",\"link-to\",\"linkTo\",\"divider\"],[[29,\"component\",[[24,0,[\"itemComponent\"]]],null],[29,\"component\",[[24,0,[\"linkToComponent\"]]],null],[29,\"component\",[[24,0,[\"linkToComponent\"]]],null],[29,\"component\",[[24,0,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});