define("ember-keyboard/utils/handle-key-event", ["exports", "ember-keyboard/utils/get-mouse-name", "ember-keyboard/utils/get-code", "ember-keyboard/utils/listener-name"], function (_exports, _getMouseName, _getCode, _listenerName3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleKeyEventWithPropagation = handleKeyEventWithPropagation;
  _exports.handleKeyEventWithLaxPriorities = handleKeyEventWithLaxPriorities;

  function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function gatherKeys(event) {
    var key = (0, _getCode.default)(event);
    var mouseButton = (0, _getMouseName.default)(event.button);
    var primaryEvent = [];
    if (Ember.isPresent(key)) primaryEvent.push(key);
    if (Ember.isPresent(mouseButton)) primaryEvent.push(mouseButton);
    return ['alt', 'ctrl', 'meta', 'shift'].reduce(function (keys, keyName) {
      if (event["".concat(keyName, "Key")]) {
        keys.push(keyName);
      }

      return keys;
    }, primaryEvent);
  }

  function handleKeyEventWithPropagation(event, _ref) {
    var firstResponders = _ref.firstResponders,
        normalResponders = _ref.normalResponders;
    var keys = gatherKeys(event);
    var listenerNames = [(0, _listenerName3.default)(event.type, keys), (0, _listenerName3.default)(event.type)];
    var isImmediatePropagationStopped = false;
    var isPropagationStopped = false;
    var ekEvent = {
      stopImmediatePropagation: function stopImmediatePropagation() {
        isImmediatePropagationStopped = true;
      },
      stopPropagation: function stopPropagation() {
        isPropagationStopped = true;
      }
    };

    var _iterator = _createForOfIteratorHelper(firstResponders),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var responder = _step.value;

        var _iterator3 = _createForOfIteratorHelper(listenerNames),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _listenerName = _step3.value;
            responder.trigger(_listenerName, event, ekEvent);
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }

        if (isImmediatePropagationStopped) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (isPropagationStopped) {
      return;
    }

    isImmediatePropagationStopped = false;
    var previousPriorityLevel = Number.POSITIVE_INFINITY;

    var _iterator2 = _createForOfIteratorHelper(normalResponders),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _responder = _step2.value;
        var currentPriorityLevel = Number(Ember.get(_responder, 'keyboardPriority'));

        if (isImmediatePropagationStopped && currentPriorityLevel === previousPriorityLevel) {
          continue;
        }

        if (currentPriorityLevel < previousPriorityLevel) {
          if (isPropagationStopped) {
            return;
          }

          isImmediatePropagationStopped = false;
          previousPriorityLevel = currentPriorityLevel;
        }

        var _iterator4 = _createForOfIteratorHelper(listenerNames),
            _step4;

        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var _listenerName2 = _step4.value;

            _responder.trigger(_listenerName2, event, ekEvent);
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }

  function handleKeyEventWithLaxPriorities(event, sortedResponders) {
    var currentPriorityLevel;
    var noFirstResponders = true;
    var isLax = true;
    var keys = gatherKeys(event);
    var listenerNames = [(0, _listenerName3.default)(event.type)];
    if (keys.length > 0) listenerNames.unshift((0, _listenerName3.default)(event.type, keys));
    sortedResponders.every(function (responder) {
      var keyboardFirstResponder = Ember.get(responder, 'keyboardFirstResponder');
      var keyboardPriority = Ember.get(responder, 'keyboardPriority');

      if (keyboardFirstResponder || noFirstResponders && keyboardPriority >= currentPriorityLevel || isLax) {
        if (!Ember.get(responder, 'keyboardLaxPriority')) {
          isLax = false;
        }

        if (keyboardFirstResponder) {
          if (!isLax) {
            noFirstResponders = false;
          }
        } else {
          currentPriorityLevel = keyboardPriority;
        }

        listenerNames.forEach(function (triggerName) {
          if (responder.has(triggerName)) {
            responder.trigger(triggerName, event);
          }
        });
        return true;
      } else {
        return false;
      }
    });
  }
});