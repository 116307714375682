define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vv9SQrhz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[29,\"if\",[[24,0,[\"fluid\"]],\"container-fluid\",\"container\"],null]],[9],[0,\"\\n  \"],[15,1,[[29,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[29,\"component\",[[24,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[24,0,[\"toggleNavbar\"]],[25,[\"_collapsed\"]]]]],[29,\"component\",[[24,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[25,[\"_collapsed\"]],[25,[\"onCollapsed\"]],[25,[\"onExpanded\"]]]]],[29,\"component\",[[24,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[29,\"component\",[[24,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[24,0,[\"collapse\"]]]]]]]],[24,0,[\"collapse\"]],[24,0,[\"expand\"]],[24,0,[\"toggleNavbar\"]]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});