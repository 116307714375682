define("ember-keyboard/fixtures/valid-keys", ["exports", "ember-keyboard/fixtures/code-map", "ember-keyboard/fixtures/modifiers-array", "ember-keyboard/fixtures/non-unique-valid-keys"], function (_exports, _codeMap, _modifiersArray, _nonUniqueValidKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keyMapValues = Object.keys(_codeMap.default).map(function (key) {
    return _codeMap.default[key];
  });

  var _default = keyMapValues.concat(_modifiersArray.default).concat(_nonUniqueValidKeys.default);

  _exports.default = _default;
});