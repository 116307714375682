define("ember-bootstrap/templates/components/bs-accordion/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pF1DvMrR",
    "block": "{\"symbols\":[\"@class\",\"&default\",\"@collapsed\"],\"statements\":[[4,\"component\",[[24,0,[\"collapseComponent\"]]],[[\"ariaRole\",\"collapsed\",\"class\"],[\"tabpanel\",[24,3,[]],\"panel-collapse\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"panel-body \",[24,1,[]]]]],[9],[0,\"\\n    \"],[15,2],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/body.hbs"
    }
  });

  _exports.default = _default;
});