define("ember-bootstrap/templates/components/bs-popover/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YXc8F+vB",
    "block": "{\"symbols\":[\"@title\",\"@destinationElement\",\"@id\",\"&attrs\",\"&default\"],\"statements\":[[5,\"ember-popper\",[[14,4]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[24,0,[\"ariaRole\"]],[24,0,[\"placement\"]],[24,0,[\"renderInPlace\"]],[24,0,[\"popperTarget\"]],[24,0,[\"popperModifiers\"]],[24,2,[]],[24,0,[\"updatePlacement\"]],[24,0,[\"updatePlacement\"]],[24,3,[]],[24,0,[\"popperClass\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[24,0,[\"arrowClass\"]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[24,0,[\"hasTitle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[12,\"class\",[24,0,[\"titleClass\"]]],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[12,\"class\",[24,0,[\"contentClass\"]]],[9],[15,5],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover/element.hbs"
    }
  });

  _exports.default = _default;
});