define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Df8SHX/A",
    "block": "{\"symbols\":[\"&default\",\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[24,0,[\"horizontalInputGridClass\"]],\" \",[24,0,[\"horizontalInputOffsetGridClass\"]]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"checkbox\"],[9],[0,\"\\n\"],[4,\"component\",[[24,2,[]]],null,{\"statements\":[[0,\"      \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[1,[29,\"component\",[[24,3,[]]],null],false],[0,\"\\n  \"],[1,[29,\"component\",[[24,4,[]]],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox.hbs"
    }
  });

  _exports.default = _default;
});