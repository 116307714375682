define("ember-content-placeholders/templates/components/content-placeholders-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jd3XLGMP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"img\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__img\"]]],[11,\"data-test-ember-content-placeholders-heading-img\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__content\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__title\"]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__subtitle\"]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-placeholders/templates/components/content-placeholders-heading.hbs"
    }
  });

  _exports.default = _default;
});