define("ember-content-placeholders/templates/components/content-placeholders-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2yhVnXZG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__logo\"]]],[9],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__content\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__item\"]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__item\"]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"className\"],\"__item\"]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-placeholders/templates/components/content-placeholders-nav.hbs"
    }
  });

  _exports.default = _default;
});