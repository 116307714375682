define("ember-bootstrap/templates/components/bs-form/element/layout/vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RuMX5c/5",
    "block": "{\"symbols\":[\"@labelComponent\",\"&default\",\"@feedbackIconComponent\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[4,\"if\",[[24,0,[\"hasLabel\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[24,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[15,2],[0,\"\\n\"],[1,[29,\"component\",[[24,3,[]]],null],false],[0,\"\\n\"],[1,[29,\"component\",[[24,4,[]]],null],false],[0,\"\\n\"],[1,[29,\"component\",[[24,5,[]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical.hbs"
    }
  });

  _exports.default = _default;
});