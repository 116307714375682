define("ember-bootstrap/templates/components/bs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D1Z2Gr67",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"item\",\"link-to\",\"linkTo\",\"dropdown\"],[[29,\"component\",[[24,0,[\"itemComponent\"]]],null],[29,\"component\",[[24,0,[\"linkToComponent\"]]],null],[29,\"component\",[[24,0,[\"linkToComponent\"]]],null],[29,\"component\",[[24,0,[\"dropdownComponent\"]]],[[\"inNav\",\"tagName\"],[true,\"li\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs"
    }
  });

  _exports.default = _default;
});