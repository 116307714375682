define("ember-keyboard/utils/trigger-event", ["exports", "ember-keyboard/utils/get-cmd-key", "ember-keyboard", "ember-keyboard/fixtures/modifiers-array"], function (_exports, _getCmdKey, _emberKeyboard, _modifiersArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerKeyUp = _exports.triggerKeyPress = _exports.triggerKeyDown = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var triggerKeyEvent = function triggerKeyEvent(eventType, rawCode, element) {
    var event = new Event(eventType);
    var parts = rawCode.split('+');

    var _parts$filter = parts.filter(function (part) {
      return !_modifiersArray.default.includes(part);
    }),
        _parts$filter2 = _slicedToArray(_parts$filter, 1),
        code = _parts$filter2[0];

    var modifiers = parts.filter(function (part) {
      return part !== code;
    });
    var properties = modifiers.reduce(function (properties, modifier) {
      modifier = modifier === 'cmd' ? (0, _getCmdKey.default)() : modifier;
      properties["".concat(modifier, "Key")] = true;
      return properties;
    }, {});
    Ember.assign(event, {
      code: code,
      keyCode: (0, _emberKeyboard.getKeyCode)(code)
    }, properties);
    (element || document).dispatchEvent(event);
  };

  var triggerKeyDown = function triggerKeyDown(code, element) {
    triggerKeyEvent('keydown', code, element);
  };

  _exports.triggerKeyDown = triggerKeyDown;

  var triggerKeyPress = function triggerKeyPress(code, element) {
    triggerKeyEvent('keypress', code, element);
  };

  _exports.triggerKeyPress = triggerKeyPress;

  var triggerKeyUp = function triggerKeyUp(code, element) {
    triggerKeyEvent('keyup', code, element);
  };

  _exports.triggerKeyUp = triggerKeyUp;
});