define("ember-bootstrap/templates/components/bs-form/element/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dj/cHnJe",
    "block": "{\"symbols\":[\"message\",\"@messages\"],\"statements\":[[4,\"if\",[[24,0,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[24,0,[\"showMultipleErrors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"pre-scrollable\"],[9],[0,\"\\n\"],[4,\"each\",[[24,2,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[23,\"feedbackClass\"]],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[24,0,[\"feedbackClass\"]]],[9],[1,[24,2,[\"firstObject\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/errors.hbs"
    }
  });

  _exports.default = _default;
});