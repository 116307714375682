define('ember-crumbly/helpers/model-type-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.modelTypeName = modelTypeName;
  function modelTypeName(model) {
    if (model && model.constructor && model.constructor.modelName) {
      return model.constructor.modelName.capitalize();
    } else if (model && model.content && model.content._internalModel && model.content._internalModel.modelName) {
      return model.get('_internalModel.modelName').capitalize();
    } else {
      return '';
    }
  }

  var Helper = Ember.Helper;
  exports.default = Helper.helper(modelTypeName);
});