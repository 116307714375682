define("ember-bootstrap/templates/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GJi6Pg/s",
    "block": "{\"symbols\":[\"@onClose\",\"@submitTitle\",\"@onSubmit\",\"&default\"],\"statements\":[[4,\"if\",[[26,4]],null,{\"statements\":[[0,\"  \"],[15,4],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,0,[\"hasSubmitButton\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[24,0,[\"buttonComponent\"]]],[[\"onClick\"],[[24,1,[]]]],{\"statements\":[[1,[24,0,[\"closeTitle\"]],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"component\",[[24,0,[\"buttonComponent\"]]],[[\"type\",\"onClick\",\"_disabled\"],[[24,0,[\"submitButtonType\"]],[24,3,[]],[24,0,[\"submitDisabled\"]]]],{\"statements\":[[1,[24,2,[]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[24,0,[\"buttonComponent\"]]],[[\"type\",\"onClick\"],[\"primary\",[24,1,[]]]],{\"statements\":[[1,[24,0,[\"closeTitle\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs"
    }
  });

  _exports.default = _default;
});