define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9YHxlCu0",
    "block": "{\"symbols\":[\"@scrollable\",\"@centered\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"modal-dialog \",[24,0,[\"sizeClass\"]],\" \",[29,\"if\",[[24,2,[]],\"modal-dialog-centered\"],null],\" \",[29,\"if\",[[24,1,[]],\"modal-dialog-scrollable\"],null]]]],[11,\"role\",\"document\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n    \"],[15,3],[0,\"\\n  \"],[3,\"focus-trap\",null,[[\"focusTrapOptions\"],[[29,\"hash\",null,[[\"initialFocus\",\"clickOutsideDeactivates\"],[[24,0,[\"initialFocus\"]],true]]]]]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs"
    }
  });

  _exports.default = _default;
});