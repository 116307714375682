define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rl++Cena",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[29,\"component\",[[24,0,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"_disabled\",\"_readonly\",\"onChange\",\"_onChange\"],[[24,0,[\"model\"]],[24,0,[\"formLayout\"]],[24,0,[\"horizontalLabelGridClass\"]],[24,0,[\"showAllValidations\"]],[24,0,[\"disabled\"]],[24,0,[\"readonly\"]],[24,0,[\"elementChanged\"]],[24,0,[\"resetSubmissionState\"]]]]],[29,\"component\",[[24,0,[\"groupComponent\"]]],[[\"formLayout\"],[[24,0,[\"formLayout\"]]]]],[24,0,[\"isSubmitting\"]],[24,0,[\"isSubmitted\"]],[24,0,[\"isRejected\"]],[24,0,[\"resetSubmissionState\"]],[24,0,[\"doSubmit\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});