define('ember-crumbly/components/bread-crumbs', ['exports', 'ember-crumbly/templates/components/bread-crumbs', 'ember-crumbly/helpers/model-type-name'], function (exports, _breadCrumbs, _modelTypeName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      _get = Ember.get,
      Component = Ember.Component,
      computed = Ember.computed,
      getWithDefault = Ember.getWithDefault,
      assert = Ember.assert,
      typeOf = Ember.typeOf,
      setProperties = Ember.setProperties,
      emberArray = Ember.A,
      classify = Ember.String.classify;
  var bool = computed.bool,
      readOnly = computed.readOnly;
  exports.default = Component.extend({
    layout: _breadCrumbs.default,
    tagName: 'ol',
    linkable: true,
    reverse: false,
    routesForInjection: [],
    classNameBindings: ['breadCrumbClass'],
    hasBlock: bool('template').readOnly(),
    currentUrl: readOnly('applicationRoute.router.url'),
    currentRouteName: readOnly('applicationRoute.controller.currentRouteName'),

    routeHierarchy: computed('currentUrl', 'currentRouteName', 'reverse', {
      get: function get() {
        var currentRouteName = getWithDefault(this, 'currentRouteName', false);

        assert('[ember-crumbly] Could not find a curent route', currentRouteName);

        var routeNames = currentRouteName.split('.');
        var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
        var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);
        var injectedCrumbs = this._injectCrumbs(crumbs);

        return _get(this, 'reverse') ? injectedCrumbs.reverse() : injectedCrumbs;
      }
    }).readOnly(),

    breadCrumbClass: computed('outputStyle', {
      get: function get() {
        var className = 'breadcrumb';
        var outputStyle = getWithDefault(this, 'outputStyle', '');
        var lowerCaseOutputStyle = outputStyle.toLowerCase();

        if (lowerCaseOutputStyle === 'foundation') {
          className = 'breadcrumbs';
        }

        return className;
      }
    }).readOnly(),

    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        var path = name + '.index';

        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },
    _filterIndexAndLoadingRoutes: function _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(function (name) {
        return !(name === 'index' || name === 'loading');
      });
    },
    _lookupRoute: function _lookupRoute(routeName) {
      return Ember.getOwner(this).lookup('route:' + routeName);
    },
    _createAdditionalPath: function _createAdditionalPath(crumb) {
      var path = void 0,
          id = void 0,
          pathObj = {};

      if (crumb.model) {
        var model = crumb.model;
        var modelType = (0, _modelTypeName.modelTypeName)(model).toLowerCase();

        if (crumb.path) {
          pathObj.path = _get(crumb, 'path');
        } else {
          pathObj.path = modelType.pluralize() + '.' + modelType;
        }

        pathObj.id = _get(model, 'slug') || _get(model, 'id');
      } else {
        pathObj.path = _get(crumb, 'path');
      }

      return pathObj;
    },
    _injectCrumbs: function _injectCrumbs(crumbs) {
      var flatCrumbs = crumbs.mapBy('title');
      var crumbsForInjection = _get(this, 'routesForInjection');

      crumbsForInjection.forEach(function (breadCrumb) {
        var indexOfParent = flatCrumbs.indexOf(breadCrumb.parent);
        var totalLength = flatCrumbs.length;
        var childIndex = indexOfParent + breadCrumb.offset;

        crumbs.splice(childIndex, 0, breadCrumb);
      });

      set(this, 'routesForInjection', []);

      return crumbs;
    },
    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = _get(this, 'linkable');
      var pathLength = filteredRouteNames.length;
      var breadCrumbs = filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);
        var route = _this._lookupRoute(path);
        var isHead = index === 0;
        var isTail = index === pathLength - 1;
        var crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        assert('[ember-crumbly] `route:' + path + '` was not found', route);

        var breadCrumb = getWithDefault(route, 'breadCrumb', {
          title: classify(name)
        });

        if (typeOf(breadCrumb) === 'null') {
          return;
        } else {
          setProperties(breadCrumb, {
            path: path,
            isHead: isHead,
            isTail: isTail,
            linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
          });
        }

        if (breadCrumb.injection) {
          set(_this, 'routesForInjection', []);

          var routesForInjection = _get(_this, 'routesForInjection');
          var injectionCrumbs = breadCrumb.injection.map(function (additionalCrumb) {
            var model = additionalCrumb.model;
            additionalCrumb.parent = classify(name);

            if (additionalCrumb.linkable) {
              assert('Provide model or path property if you want use linkable in injection', model || additionalCrumb.path);

              var pathObject = _this._createAdditionalPath(additionalCrumb);
              additionalCrumb = Ember.Object.create(additionalCrumb);
              additionalCrumb.setProperties(pathObject);
            }

            if (!additionalCrumb.title) {
              assert('Provide model to use default title', model);

              additionalCrumb.title = _get(model, 'name');
            }

            return additionalCrumb;
          });

          routesForInjection.pushObjects(injectionCrumbs);
        }

        return breadCrumb;
      });

      return emberArray(breadCrumbs.filter(function (breadCrumb) {
        return typeOf(breadCrumb) !== 'undefined';
      }));
    }
  });
});