define("ember-bootstrap/templates/components/bs-navbar/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FMEPn/fa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"Toggle navigation\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon-bar\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon-bar\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon-bar\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar/toggle.hbs"
    }
  });

  _exports.default = _default;
});