define("emberx-select/templates/components/x-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mNUkg/yL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"option\"],[[29,\"component\",[\"x-option\"],[[\"select\",\"register\",\"unregister\"],[[24,0,[]],[29,\"action\",[[24,0,[]],\"registerOption\"],null],[29,\"action\",[[24,0,[]],\"unregisterOption\"],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "emberx-select/templates/components/x-select.hbs"
    }
  });

  _exports.default = _default;
});