define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XblK5XIE",
    "block": "{\"symbols\":[\"@destinationElement\",\"@id\",\"&attrs\",\"&default\"],\"statements\":[[5,\"ember-popper\",[[14,3]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[24,0,[\"ariaRole\"]],[24,0,[\"placement\"]],[24,0,[\"renderInPlace\"]],[24,0,[\"popperTarget\"]],[24,0,[\"popperModifiers\"]],[24,1,[]],[24,0,[\"updatePlacement\"]],[24,0,[\"updatePlacement\"]],[24,2,[]],[24,0,[\"popperClass\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[24,0,[\"arrowClass\"]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"tooltip-inner\"],[9],[0,\"\\n    \"],[15,4],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
    }
  });

  _exports.default = _default;
});