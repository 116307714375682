define("ember-content-placeholders/templates/components/content-placeholders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QxObGDXS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"heading\",\"text\",\"img\",\"nav\"],[[29,\"component\",[\"content-placeholders-heading\"],[[\"rounded\",\"animated\",\"centered\"],[[25,[\"rounded\"]],[25,[\"animated\"]],[25,[\"centered\"]]]]],[29,\"component\",[\"content-placeholders-text\"],[[\"rounded\",\"animated\",\"centered\"],[[25,[\"rounded\"]],[25,[\"animated\"]],[25,[\"centered\"]]]]],[29,\"component\",[\"content-placeholders-img\"],[[\"rounded\",\"animated\",\"centered\"],[[25,[\"rounded\"]],[25,[\"animated\"]],[25,[\"centered\"]]]]],[29,\"component\",[\"content-placeholders-nav\"],[[\"rounded\",\"animated\",\"centered\"],[[25,[\"rounded\"]],[25,[\"animated\"]],[25,[\"centered\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-placeholders/templates/components/content-placeholders.hbs"
    }
  });

  _exports.default = _default;
});