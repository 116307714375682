function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

(function () {
  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc = _slicedToArray(possibleDesc, 3),
        target = _possibleDesc[0],
        key = _possibleDesc[1],
        desc = _possibleDesc[2];

    return possibleDesc.length === 3 && _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
    ;
  }

  function computedMacroWithOptionalParams(fn) {
    return function () {
      for (var _len = arguments.length, maybeDesc = new Array(_len), _key = 0; _key < _len; _key++) {
        maybeDesc[_key] = arguments[_key];
      }

      return isFieldDescriptor(maybeDesc) ? Function.apply.call(fn(), undefined, maybeDesc) : Function.apply.call(fn, undefined, maybeDesc);
    };
  }

  var originalRequire = window.require;

  window.require = require = function patchDataDecorators(moduleName) {
    var DS;

    try {
      DS = originalRequire('ember-data').default;
    } catch (e) {
      return originalRequire(moduleName);
    }

    var _DS = DS,
        dataAttr = _DS.attr,
        dataBelongsTo = _DS.belongsTo,
        dataHasMany = _DS.hasMany;
    var attr = computedMacroWithOptionalParams(dataAttr);
    var belongsTo = computedMacroWithOptionalParams(dataBelongsTo);
    var hasMany = computedMacroWithOptionalParams(dataHasMany);
    DS.attr = attr;
    DS.belongsTo = belongsTo;
    DS.hasMany = hasMany;

    if (window.requirejs.entries['@ember-data/model/index']) {
      var newExports = Object.assign({}, window.requirejs.entries['@ember-data/model/index'].module.exports, {
        attr: attr,
        belongsTo: belongsTo,
        hasMany: hasMany
      });
      window.requirejs.entries['@ember-data/model/index'].module.exports = newExports;
    }

    window.require = require = window.requirejs;
    return window.requirejs(moduleName);
  };
})();